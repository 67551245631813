import { Breadcrumbs, Layout } from "@heart/components";
import PropTypes from "prop-types";
import { adminChildrenPath } from "routes";

import { translationWithRoot } from "@components/T";
import AgencyServiceRecordForm from "@components/prevention/agency_service_records/AgencyServiceRecordForm";

const { T } = translationWithRoot("prevention.agency_service_records");

/**
 * @param {Array} methodsOfEngagement
 * @param {Array} serviceRates
 * @param {Array} serviceReferralRecipients
 */
const AgencyServiceRecordFormPage = ({
  methodsOfEngagement,
  numUnits,
  serviceRates,
  serviceReferralRecipients,
}) => (
  <Layout
    pageTitle={<T t="log_new" />}
    breadcrumbs={
      <Breadcrumbs
        pages={[
          {
            label: <T t="breadcrumbs.home" />,
            href: adminChildrenPath(),
          },
          {
            label: <T t="log_new" />,
            href: "#",
          },
        ]}
      />
    }
    main={{
      content: (
        <AgencyServiceRecordForm
          methodsOfEngagement={methodsOfEngagement}
          numUnits={numUnits}
          serviceRates={serviceRates}
          serviceReferralRecipients={serviceReferralRecipients}
        />
      ),
    }}
  />
);

AgencyServiceRecordFormPage.propTypes = {
  methodsOfEngagement: PropTypes.array,
  numUnits: PropTypes.number,
  serviceRates: PropTypes.array,
  serviceReferralRecipients: PropTypes.array,
};

export default AgencyServiceRecordFormPage;
