import { Button, Flex, Icons, Modal, Text } from "@heart/components";
import PropTypes from "prop-types";
import { useState } from "react";

/** Silly little easter egg for Binti Pursuit circa October 2024 */
const ShhhEasterEgg = ({ k8sNamespace }) => {
  const [hidden, setHidden] = useState(true);
  return (
    <If condition={k8sNamespace === "qa"}>
      <Button
        icon={Icons.Star}
        onClick={() => setHidden(false)}
        variant="tertiary"
        description="shhh it's a secret!"
      />
      <Modal
        title="	&#128075;"
        hideCancel
        hidden={hidden}
        submitText="Close"
        onCancel={() => setHidden(true)}
        onSubmit={() => setHidden(true)}
      >
        <Flex column gap="300" align="center">
          We hope you&apos;re enjoying your time connecting with other Bintians
          and exploring our product! &#127881; Did you know that Binti means
          &apos;daughter&apos; in Swahili?
          <img
            src="https://cdn.dribbble.com/users/934149/screenshots/14906513/media/a67557775535a7650548b65b4ad18cc8.gif"
            alt="meow"
            style={{ width: "90%" }}
          />
          <Text textStyle="supporting-50">
            gif by{" "}
            <a
              href="https://dribbble.com/karolin_chen"
              target="_blank"
              rel="noreferrer"
            >
              Karolin Chen
            </a>
          </Text>
        </Flex>
      </Modal>
    </If>
  );
};
ShhhEasterEgg.propTypes = {
  k8sNamespace: PropTypes.string,
};

export default ShhhEasterEgg;
