import {
  SurfaceForm,
  InputFilterable,
  InputDate,
  InputDropdown,
  Actions,
  InputTime,
  InputText,
  InputTextarea,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import preventDefault from "@lib/preventDefault";

const { T } = translationWithRoot("prevention.agency_service_records");

/**
 * @param {Array} methodsOfEngagement
 * @param {Number} numUnits
 * @param {Array} serviceRates
 * @param {Array} serviceReferralRecipients
 */
const AgencyServiceRecordForm = ({
  methodsOfEngagement,
  numUnits,
  serviceRates,
  serviceReferralRecipients,
}) => {
  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: {},
  });

  const handleSubmit = () => {};

  const selectedRateBillingStructure = formState.serviceRateId
    ? serviceRates.find(rate => rate.id.toString() === formState.serviceRateId)
        .billingStructure
    : null;

  return (
    <SurfaceForm
      actions={<Actions />}
      title={<T t="log_new" />}
      onSubmit={preventDefault(handleSubmit)}
    >
      <InputDate
        label={<T t="service_date" />}
        onChange={setFormAttribute("serviceDate")}
        required
      />
      <InputFilterable
        isMulti
        label={<T t="recipients" />}
        onChange={setFormAttribute("recipientIds")}
        values={serviceReferralRecipients}
        required
      />
      <InputDropdown
        hideBlank
        label={<T t="select_rate" />}
        onChange={setFormAttribute("serviceRateId")}
        required
        values={serviceRates.map(rate => [rate.label, rate.id])}
      />
      <InputDropdown
        hideBlank
        label={<T t="method_of_engagement" />}
        onChange={setFormAttribute("methodOfEngagement")}
        values={methodsOfEngagement}
        required
      />
      <If condition={formState.methodOfEngagement === "Other"}>
        <InputText
          label={<T t="method_of_engagement_other" />}
          onChange={setFormAttribute("methodOfEngagementOther")}
          required
        />
      </If>
      <InputTime
        label={<T t="start_time" />}
        onChange={setFormAttribute("startTime")}
        required
      />
      <InputTime
        label={<T t="end_time" />}
        onChange={setFormAttribute("endTime")}
        required
      />
      <If condition={selectedRateBillingStructure === "hourly"}>
        <InputText
          label={<T t="time_billed" />}
          type="number"
          step="15"
          onChange={setFormAttribute("timeBilled")}
          required
        />
      </If>
      <If condition={selectedRateBillingStructure === "fee_per_service"}>
        <InputDropdown
          hideBlank
          label={<T t="units_utilized" />}
          onChange={setFormAttribute("unitsUtilized")}
          required
          values={Array.from({ length: numUnits + 1 }, (_, i) => [i, i])}
        />
      </If>
      <If condition={selectedRateBillingStructure === "mileage"}>
        <InputText
          label={<T t="distance" />}
          type="number"
          onChange={setFormAttribute("miles")}
          required
        />
      </If>
      <InputTextarea
        label={<T t="notes" />}
        onChange={setFormAttribute("notes")}
        required
      />
    </SurfaceForm>
  );
};

AgencyServiceRecordForm.propTypes = {
  methodsOfEngagement: PropTypes.array,
  numUnits: PropTypes.number,
  serviceRates: PropTypes.array,
  serviceReferralRecipients: PropTypes.array,
};

export default AgencyServiceRecordForm;
