import { useQuery } from "@apollo/client";
import {
  Breadcrumbs,
  ContentTabs,
  Layout,
  LoadingOverlay,
  Surface,
} from "@heart/components";
import {
  adminRootPath,
  agencyServicesPath,
  preventionAgencyServiceReferralsPath,
} from "routes";

import { translationWithRoot } from "@components/T";
import DashboardTable from "@components/prevention/agency_service_referrals/DashboardTable";

import CurrentUser from "@graphql/queries/CurrentUser.graphql";

const { T, t } = translationWithRoot(
  "prevention.agency_service_referrals.dashboard"
);

/**
 * Creates a Dashboard page for the Agency Service Referrals
 */
const DashboardPage = () => {
  const { data, loading } = useQuery(CurrentUser);

  const content = (
    <LoadingOverlay active={loading}>
      <Surface title={<T t="title" />} hideTitle>
        <ContentTabs
          tabs={[
            {
              title: t("tabs.pending"),
              contents: (
                <DashboardTable
                  status={"pending"}
                  currentUserAgencyId={data?.user?.agencyId}
                />
              ),
            },
            {
              title: t("tabs.in_progress"),
              contents: (
                <DashboardTable
                  status={"in_progress"}
                  currentUserAgencyId={data?.user?.agencyId}
                />
              ),
            },
            {
              title: t("tabs.completed"),
              contents: (
                <DashboardTable
                  status={"completed"}
                  currentUserAgencyId={data?.user?.agencyId}
                />
              ),
            },
            {
              title: t("tabs.rejected"),
              contents: (
                <DashboardTable
                  status={"rejected"}
                  currentUserAgencyId={data?.user?.agencyId}
                />
              ),
            },
          ]}
        />
      </Surface>
    </LoadingOverlay>
  );

  return (
    <Layout
      pageTitle={<T t="header" />}
      breadcrumbs={
        <Breadcrumbs
          pages={[
            {
              label: <T t="breadcrumbs.home" />,
              href: adminRootPath(),
            },
            {
              label: <T t="breadcrumbs.children_and_youth" />,
              href: agencyServicesPath(),
            },
            {
              label: <T t="breadcrumbs.title" />,
              href: preventionAgencyServiceReferralsPath(),
            },
          ]}
        />
      }
      main={{ content }}
    />
  );
};

export default DashboardPage;
