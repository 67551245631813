import { Button, Flex, InputText } from "@heart/components";
import { PaperPlane } from "@heart/components/icon/Icon";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState } from "react";

import styles from "./VoiceChat.module.scss";

/**
 * Component that allows the user to type a message and submit it using enter.
 * This input is really just backup in case the voice recognition fails for
 * certain words. The expectation is that voice chat is hands free.
 *
 * @param onNewMessage callback function to call when a new message is sent
 */
const ChatTextInput = ({ onNewMessage }) => {
  const [text, setText] = useState("");

  const handleKeyDown = async event => {
    if (event.key === "Enter") {
      event.preventDefault();

      setText("");

      await onNewMessage(text);
    }
  };

  return (
    <Flex row className={styles.chatTextInputContainer}>
      <InputText
        fullWidth
        onKeyDown={handleKeyDown}
        value={text}
        onChange={setText}
        label={I18n.t(
          "javascript.components.voice_navigation.common.chat_text_input.label"
        )}
        hideLabel
      />
      <Button
        onClick={() => {
          onNewMessage(text);
          setText("");
        }}
        icon={PaperPlane}
        description="Send"
      />
    </Flex>
  );
};

ChatTextInput.propTypes = {
  onNewMessage: PropTypes.func.isRequired,
};

export default ChatTextInput;
