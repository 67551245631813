import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./ResolutionOnly.module.scss";

/**
 * Helper component for hiding elements at certain resolution ranges.
 *
 * The resolution-swapping props are as follows:
 *   * either `small`, `medium`, or `large` (boolean, choose one)
 *   * whether you want the children to be visible under that breakpoint,
 *     or over that breakpoint: one of `over` or `under`.
 *
 * For example, these two instances are complimentary and will swap out for
 * one another at `$break-small`:
 *
 * ```jsx
 * <ResolutionOnly small under>Small</ResolutionOnly>
 * <ResolutionOnly small over>Medium and Up</ResolutionOnly>
 * ```
 *
 * For applying CSS rules (like changing background colors) at certain breakpoints,
 * have a look at the mixins in `@heart/core/breakpoints.scss`!
 *
 * If adding this extra wrapper messes with the alignment of items in a
 * `<Flex row>`, use the `as` prop with the value `Flex`:
 *
 * ```jsx
 * <ResolutionOnly small under as={Flex} />
 * ```
 *
 * *Please note* that you'll need to go into Canvas to see the stories
 * at various viewports.
 *
 * ### Jest
 * We mock this component globally in our Jest setup so that it doesn't
 * render mobile views by default. Because Jest runs in a browserless
 * environment, without this mock we'd get doubles of things in all our
 * snapshots where we have deviation points.
 *
 * If needed, you can override this global mock in a specific test
 */
const ResolutionOnly = ({ children, ...props }) => {
  const { small, medium, large, under, over, ...otherProps } = props;

  const className = classNames({
    [styles.smDown]: small && over,
    [styles.smUp]: small && under,
    [styles.mdDown]: medium && over,
    [styles.mdUp]: medium && under,
    [styles.lgDown]: large && over,
    [styles.lgUp]: large && under,
  });

  return (
    <div {...otherProps} className={className}>
      {children}
    </div>
  );
};

ResolutionOnly.propTypes = {
  seeCommentAbove: props => {
    const chosenSizes = [props.small, props.medium, props.large].filter(
      Boolean
    );
    if (chosenSizes.length > 1)
      return new Error("Choose only one of `small`, `medium`, or `large`");

    if (chosenSizes.length === 0)
      return new Error(
        "One of `small`, `medium`, or `large` props is required"
      );

    const chosenRanges = [props.over, props.under].filter(Boolean);
    if (chosenRanges.length > 1)
      return new Error("Choose only one of `under` or `over`");

    if (chosenRanges.length === 0)
      return new Error("One of `under` or `over` props is required");
    return undefined;
  },
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  under: PropTypes.bool,
  over: PropTypes.bool,
  /** Element children to show or hide via CSS */
  children: PropTypes.node,
};
export default ResolutionOnly;
