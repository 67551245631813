import PropTypes from "prop-types";

export const ASSISTANT = "assistant";
export const USER = "user";

export const MessagePropType = PropTypes.shape({
  role: PropTypes.oneOf([ASSISTANT, USER]).isRequired,
  message: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
});
