import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot(
  "prevention.agency_services.dashboard.filters"
);

/**
 * Creates a list of filters
 * @param fundingSources
 * @returns list of filter configs
 */
const CreateFilters = fundingSources => [
  {
    label: t("provider_name"),
    type: "search",
    field: "providerAgencyNameContains",
  },
  {
    label: t("service_name"),
    type: "search",
    field: "serviceNameContains",
  },
  {
    label: t("ffpsa"),
    type: "dropdown",
    field: "ffpsa",
    values: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
  },
  {
    label: t("funding_sources"),
    type: "select",
    values: fundingSources,
    field: "fundingSources",
    isMulti: true,
  },
  // TODO(ENG-18895): Add Dynamic filters (area of coverage, funding source, ebps and service type)
  {
    label: "",
    type: "custom_dates",
    field: "startDate",
    customDatesLabels: {
      start: t("start_date_after"),
      end: t("start_date_before"),
    },
  },
  {
    label: "",
    type: "custom_dates",
    field: "endDate",
    customDatesLabels: {
      start: t("end_date_after"),
      end: t("end_date_before"),
    },
  },
];

export default CreateFilters;
