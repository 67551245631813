import { useLazyQuery, useMutation, useQuery, gql } from "@apollo/client";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import { translationWithRoot } from "@components/T";

import GenerateHellosignPartialPdf from "@graphql/mutations/GenerateHellosignPdf.graphql";
import ApplicationForm from "@graphql/queries/ApplicationForm.graphql";
import FeatureFlag from "@graphql/queries/FeatureFlag.graphql";

import useFeatureFlag from "@lib/useFeatureFlag";

import styles from "./ApplicationRequirementTable.module.scss";
import DownloadIncompleteForm from "./DownloadLink";
import GenerateIncompletePdf from "./GenerateIncompletePdf";

// Stop polling after 8 seconds
const MAX_POLLING_ATTEMPTS = 8;

const AttachmentActions = ({ fulfillment, requirement, application }) => {
  const { flag: ffTranslationAi052024 } = useFeatureFlag(
    "ff_translation_ai_05_2024"
  );
  const ApplicationQuery = gql`
    query Application($id: ID!) {
      application(id: $id) {
        cpaOrGroupCare
        applicantAgencyProfile {
          user {
            locale
          }
        }
      }
    }
  `;
  const { data: applicationData } = useQuery(ApplicationQuery, {
    variables: { id: application?.id },
  });
  // get the locale for the primary applicant on the application as this locale sets
  // the language for the whole application
  const locale =
    applicationData?.application?.applicantAgencyProfile?.user?.locale;

  const { t } = translationWithRoot("application_requirements");

  const [generateHellosignPartial, { loading: mutationLoading }] = useMutation(
    GenerateHellosignPartialPdf
  );
  const [getApplicationForm, { stopPolling, data: queryData }] =
    useLazyQuery(ApplicationForm);
  const [pollingCount, setPollingCount] = useState(0);
  const [polling, setPolling] = useState(false);
  const { data: { featureFlag: ffHellosignPartial022024 } = {} } = useQuery(
    FeatureFlag,
    {
      variables: { flag: "ff_hellosign_partial_02_2024" },
    }
  );

  const generatePartialPdfMutation = formInstanceId => {
    generateHellosignPartial({
      variables: {
        applicationFormId: formInstanceId,
      },
    });
    getApplicationForm({
      variables: {
        applicationFormId: formInstanceId,
      },
      pollInterval: 1000,
      onCompleted: () => {
        setPollingCount(1);
        setPolling(true);
      },
    });
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      queryData?.applicationForm?.partialAvailable ||
      pollingCount === MAX_POLLING_ATTEMPTS
    ) {
      stopPolling();
      setPolling(false);
      setPollingCount(0);
    } else if (pollingCount > 0) {
      const timeoutId = setTimeout(() => {
        setPollingCount(count => count + 1);
        getApplicationForm({
          variables: { applicationFormId: fulfillment.records[0].id },
        });
      }, 1000);

      return () => clearTimeout(timeoutId);
    }
  }, [
    queryData,
    pollingCount,
    stopPolling,
    getApplicationForm,
    fulfillment.records,
  ]);

  const formOrFormVersion = fulfillment.records[0]?.form;
  const showGenerateIncompletePdf =
    ffHellosignPartial022024 &&
    !fulfillment.isFulfilled &&
    fulfillment.records[0] !== undefined &&
    formOrFormVersion?.pdfStrategy === "filledInOnHellosign" &&
    !fulfillment.records[0]?.partialAvailable;

  const showDownloadIncompleteForm =
    !fulfillment.isFulfilled &&
    fulfillment.records[0] !== undefined &&
    (formOrFormVersion?.pdfStrategy === "bintisignDoc" ||
      // only show "Download Incomplete Form" link if Hellosign partial is available
      (ffHellosignPartial022024 &&
        formOrFormVersion?.pdfStrategy === "filledInOnHellosign" &&
        (fulfillment.records[0]?.partialAvailable ||
          queryData?.applicationForm?.partialAvailable)));

  const showTranslatedForm =
    formOrFormVersion?.pdfStrategy === "bintisignDoc" &&
    fulfillment.records[0] !== undefined &&
    formOrFormVersion?.neededFor.includes("applicant") &&
    !applicationData?.application?.cpaOrGroupCare &&
    !["en", null].includes(locale);

  const onClickGeneratePdf = () => {
    if (!mutationLoading) {
      generatePartialPdfMutation(fulfillment.records[0].id);
    }
  };

  let downloadUrl;
  if (showDownloadIncompleteForm) {
    downloadUrl = `/requirements/${requirement.id}/form_instance/${fulfillment.records[0].id}`;
  }

  let downloadTranslatedUrl;
  if (showTranslatedForm) {
    downloadTranslatedUrl = `/requirements/${requirement.id}/form_instance/${fulfillment.records[0].id}/translated_form`;
  }

  return (
    <ul className={styles.attachmentActionsList}>
      <If condition={showGenerateIncompletePdf}>
        <li>
          <GenerateIncompletePdf
            onClick={onClickGeneratePdf}
            disabled={mutationLoading || polling}
            linkText={t("generate_incomplete_pdf")}
          />
        </li>
      </If>
      <If condition={showDownloadIncompleteForm}>
        <li className={styles.downloadIncompleteLink}>
          <DownloadIncompleteForm
            url={downloadUrl}
            linkText={t("download_incomplete_form")}
          />
        </li>
      </If>
      <If condition={ffTranslationAi052024 && showTranslatedForm}>
        <li>
          <DownloadIncompleteForm
            url={downloadTranslatedUrl}
            linkText={t("download_translated_pdf")}
          />
        </li>
      </If>
    </ul>
  );
};

AttachmentActions.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  requirement: PropTypes.object.isRequired,
  application: PropTypes.object,
};

export default AttachmentActions;
