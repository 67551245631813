import { Button, toast } from "@heart/components";
import { Microphone, MicrophoneSlash } from "@heart/components/icon/Icon";
import { useMountEffect } from "@react-hookz/web";
import classNames from "classnames";
import { sum } from "lodash";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";

import styles from "./VoiceInput.module.scss";
import VoiceInputRecorder from "./VoiceInputRecorder";

// this function helps to magnify small values to be more visible
// when setting the volume indicator radius. volumes tend to be
// be small but can spike to be large, so this helps compensate
// for that and make the volume indicator more visible when people
// are speaking softly.
//
// https://math.stackexchange.com/a/158515
const factor = 500;
const smallValueMagnifier = (value, n = factor) =>
  ((n + 1) * value) / (n * value + 1);

// this value corresponds to the maximum radius of the inner circle
// gradient. the circle as a whole is transparent at the edges, so
// having a maximum 90% of the inner circle lets us feather the edge.
const maxInnerColorRadius = 0.9;

/**
 * Displays a microphone icon button that allows the user to record
 * their voice input. This component is basically a UI wrapper
 * around VoiceInputRecorder.
 */
const VoiceInput = ({
  onNewMessage,
  onStart,
  onClick,
  pauseTimeout,
  startEnabled,
}) => {
  const [voiceInputRecorder, setVoiceInputRecorder] = useState(null);
  const containerRef = useRef();

  const enableListening = useCallback(async () => {
    onClick();

    if (!VoiceInputRecorder.supportsAudioRecording()) {
      toast.negative(
        I18n.t(
          "javascript.components.voice_navigation.common.voice_input.audio_not_supported"
        )
      );
      return;
    }

    if (voiceInputRecorder) {
      await voiceInputRecorder.stop();
    }

    let largestVolumeSeen = 0;
    let resetTimeout = null;

    setVoiceInputRecorder(
      await VoiceInputRecorder.createRecorder({
        onInput: onNewMessage,
        onStart,
        onSpeech: dataArray => {
          if (!containerRef.current) return;

          const currentVolume = sum(dataArray);
          if (currentVolume > largestVolumeSeen) {
            largestVolumeSeen = currentVolume;
          }

          const newInnerColorRadius = Math.floor(
            smallValueMagnifier(currentVolume / largestVolumeSeen) *
              maxInnerColorRadius *
              100
          );

          // unfortunately react isn't actually fast enough to set this
          // via state and props, so we set this var via a ref for performance
          containerRef.current.style = `--innerColorRadius: ${newInnerColorRadius}%`;
          if (resetTimeout) {
            clearTimeout(resetTimeout);
          }

          // reset the inner color radius after a short delay if there's no
          // more speaking before then.
          resetTimeout = setTimeout(() => {
            containerRef.current.style = "--innerColorRadius: 0%";
          }, 100);
        },
        pauseTimeout,
      })
    );
  }, [onNewMessage, onStart, onClick, pauseTimeout, voiceInputRecorder]);

  useMountEffect(() => {
    // if startEnabled is true, enable listening on mount
    if (startEnabled && !voiceInputRecorder) {
      enableListening();
    }
  });

  useEffect(() => {
    // changes to pauseTimeout should reset the voiceInputRecorder and
    // stop recording.
    if (voiceInputRecorder) {
      voiceInputRecorder.stop();
      setVoiceInputRecorder(null);
    }
    // only run this when pauseTimeout changes, not voiceInputRecorder
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseTimeout]);

  useEffect(() => {
    // changes to onStart should be passed to the voiceInputRecorder
    if (voiceInputRecorder) {
      voiceInputRecorder.onStart = onStart;
    }
  }, [onStart, voiceInputRecorder]);

  // stop recording when component unmounts
  useEffect(() => {
    const cleanup = async () => {
      await voiceInputRecorder?.stop();
    };

    return cleanup;
  }, [voiceInputRecorder]);

  const disableListening = async () => {
    onClick();
    await voiceInputRecorder.stop();
    setVoiceInputRecorder(null);
  };

  return (
    <div
      className={classNames(styles.container, styles.isSpeaking)}
      ref={containerRef}
    >
      <div className={styles.buttonContainer}>
        <Button
          onClick={voiceInputRecorder ? disableListening : enableListening}
          icon={voiceInputRecorder ? MicrophoneSlash : Microphone}
          description={
            voiceInputRecorder
              ? I18n.t(
                  "javascript.components.voice_navigation.common.voice_input.stop_listening"
                )
              : I18n.t(
                  "javascript.components.voice_navigation.common.voice_input.start_listening"
                )
          }
          round
        />
      </div>
    </div>
  );
};

VoiceInput.propTypes = {
  /**
   * Callback function to call when a new voice input chunk recorded.
   */
  onNewMessage: PropTypes.func.isRequired,
  /**
   * Callback function to call when the user starts speaking. Can be
   * used to interrupt playback of other audio. Note that any return
   * value is ignored, so for example promises will not be awaited.
   */
  onStart: PropTypes.func.isRequired,
  /**
   * Callback function to call when the user clicks the microphone button.
   */
  onClick: PropTypes.func.isRequired,
  /**
   * Time in milliseconds to wait before considering the user to be done
   * speaking for this chunk.
   */
  pauseTimeout: PropTypes.number,
  /**
   * Whether the audio recording should be enabled upon component mount.
   */
  startEnabled: PropTypes.bool,
};

export default VoiceInput;
