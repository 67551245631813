import { useQuery } from "@apollo/client";
import { Layout, LoadingOverlay, toast } from "@heart/components";
import useCurrentPageSearchParam from "@heart/components/layout/page_layout/useCurrentPageSearchParam";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import AgencyHumanDetails from "@graphql/queries/AgencyHumanDetails.graphql";

import AgencyHumanSidebar from "./AgencyHumanSidebar";
import AgencyHumanSummary from "./AgencyHumanSummary";

const { t } = translationWithRoot("agency_human.summary");

/** Our AgencyHuman profile's Summary page layout */
const AgencyHuman = ({ id }) => {
  // must use this hook to get the correct bolding applied to the sidebar link
  useCurrentPageSearchParam({ defaultPage: "summary" });
  const {
    data = {},
    error,
    loading,
  } = useQuery(AgencyHumanDetails, {
    variables: {
      ids: [id],
    },
  });
  const { agencyHumanDetails = [{ permissions: [] }] } = data;
  const agencyHuman = agencyHumanDetails[0];

  const pageTitle = loading ? "" : agencyHuman.fullName;

  if (error) {
    toast.negative({
      title: "Error fetching person",
      message: Object.values(error).join(", "),
    });
  }

  return (
    <LoadingOverlay active={loading}>
      <Layout
        pageTitle={t("title")}
        sidebar={{
          fullHeight: true,
          opaque: true,
          content: (
            <AgencyHumanSidebar
              avatar={{
                firstName: agencyHuman.firstName,
                lastName: agencyHuman.lastName,
              }}
              label={pageTitle}
              agencyHumanId={id}
            />
          ),
        }}
        main={{
          content: <AgencyHumanSummary agencyHuman={agencyHuman} />,
        }}
      />
    </LoadingOverlay>
  );
};
AgencyHuman.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AgencyHuman;
