import {
  offset,
  autoUpdate,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { Button, Flex, FlexItem, Icons, LiveRegion } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import { isEmpty, pick, uniqueId } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

import styles from "./Filter.module.scss";
import TableFilter from "./TableFilter";

const { t } = translationWithRoot("heart.components.filter");

const determineFilterValues = ({ filters, appliedFilterValueFor }) =>
  filters.reduce((vals, { field, type }) => {
    if (type === "custom_dates")
      return {
        ...vals,
        [`${field}Gteq`]: appliedFilterValueFor(`${field}Gteq`),
        [`${field}Lteq`]: appliedFilterValueFor(`${field}Lteq`),
      };
    return {
      ...vals,
      [field]: appliedFilterValueFor(field),
    };
  }, {});

/**
 * Our table filters component, used in conjunction with ArrayDataTable or GraphQLDataTable
 */
const Filter = ({
  applyFilters,
  appliedFilterValueFor,
  clearFilters,
  filters,
  "data-testid": testId,
}) => {
  const filterId = uniqueId();
  /** ResolutionOnly works by rendering both versions of the Filter (the
   * large screen Filter and the small screen Filter) and conditionally
   * hides one or the other depending on the screen size folks are using.
   *
   * Because of this, we need to conditionally enable/disable useDismiss as
   * we have two different instances of the Filter menu on the page at a time.
   * Leaving both active at all times means useDismiss for the non-visible
   * menu will trigger on any click, closing out the menu that is visible.
   */
  const [showFilter, setShowFilter] = useState(false);
  const [showSmallScreenFilter, setShowSmallScreenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState(
    determineFilterValues({ filters, appliedFilterValueFor })
  );

  useEffect(() => {
    setFilterValues(determineFilterValues({ filters, appliedFilterValueFor }));
  }, [appliedFilterValueFor, filters]);

  /** Large Screen Floating UI setup */
  /** Initializing Floating UI's hook */
  const { refs, floatingStyles, context } = useFloating({
    open: showFilter,
    onOpenChange: setShowFilter,
    /** Specifying how the Filter list should be aligned in relation to the button
     * that triggers the floating Filter content to appear
     */
    placement: "bottom-end",
    /** Updating the floating Filter list's position automatically so that
     * as folks scroll around the page or resize their screen the Filter list stays
     * anchored to the associated button
     */
    whileElementsMounted: autoUpdate,
    /** Setting our offset to -1 helps Filter lists display correctly */
    middleware: [offset({ crossAxis: -1 })],
  });
  const dismiss = useDismiss(context, { enabled: showFilter });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  /** Small Screen Floating UI setup */
  /** Initializing Floating UI's hook */
  const {
    refs: smallScreenRefs,
    floatingStyles: smallScreenFloatingStyles,
    context: smallScreenContext,
  } = useFloating({
    open: showFilter,
    onOpenChange: setShowFilter,
    /** Specifying how the Filter list should be aligned in relation to the button
     * that triggers the floating Filter content to appear
     */
    placement: "bottom-end",
    /** Updating the floating Filter list's position automatically so that
     * as folks scroll around the page or resize their screen the Filter list stays
     * anchored to the associated button
     */
    whileElementsMounted: autoUpdate,
    /** Setting our offset to -1 helps Filter lists display correctly */
    middleware: [offset({ crossAxis: -1 })],
  });
  const smallScreenDismiss = useDismiss(smallScreenContext, {
    enabled: showSmallScreenFilter,
  });
  const {
    getReferenceProps: smallScreenGetReferenceProps,
    getFloatingProps: smallScreenGetFloatingProps,
  } = useInteractions([smallScreenDismiss]);

  const setFilterValuesAttr = vals => {
    setFilterValues({ ...filterValues, ...vals });
  };

  let topLevelFilter;
  const collapsedFilters = [];

  filters.forEach(filter => {
    if (filter.topLevel) topLevelFilter = filter;
    else collapsedFilters.push(filter);
  });

  const determineCount = field =>
    isEmpty(appliedFilterValueFor(field)) ? 0 : 1;
  const appliedFiltersInMenuCount = ({ smallScreen }) =>
    filters.reduce((count, { field, type }) => {
      /** If we're not in a small screen view and this is the top level filter,
       * we don't count it towards the badge number displayed as it isn't part of
       * the filters menu
       */
      if (!smallScreen && field === topLevelFilter?.field) return count;
      /** We count each field individually in the filter menu, rather than counting
       * the range as one filter value, since there are two different inputs
       */
      if (type === "custom_dates") {
        const gteqCount = determineCount(`${field}Gteq`);
        const lteqCount = determineCount(`${field}Lteq`);
        return count + gteqCount + lteqCount;
      }
      return count + determineCount(field);
    }, 0);

  const renderFilterMenu = ({ smallScreen }) => {
    let showFilterMenu = showFilter;
    let setShowFilterMenu = setShowFilter;
    let setRef = refs.setReference;
    let getRefProps = getReferenceProps;
    let setFloat = refs.setFloating;
    let getFloatProps = getFloatingProps;
    let floatStyles = floatingStyles;
    let menuFilters = collapsedFilters;
    let preserveOnClear = topLevelFilter?.field;

    if (smallScreen) {
      showFilterMenu = showSmallScreenFilter;
      setShowFilterMenu = setShowSmallScreenFilter;
      setRef = smallScreenRefs.setReference;
      getRefProps = smallScreenGetReferenceProps;
      setFloat = smallScreenRefs.setFloating;
      getFloatProps = smallScreenGetFloatingProps;
      floatStyles = smallScreenFloatingStyles;
      menuFilters = filters;
      preserveOnClear = undefined;
    }

    return (
      <Flex justify="space-between">
        <If condition={!smallScreen && !isEmpty(topLevelFilter)}>
          <TableFilter
            key={topLevelFilter.field}
            filter={topLevelFilter}
            filterValues={filterValues}
            setFilterValuesAttr={async value => {
              setFilterValuesAttr(value);
              await applyFilters({ ...filterValues, ...value });
            }}
          />
        </If>
        <FlexItem>
          <If condition={!isEmpty(menuFilters)}>
            <Flex justify="end">
              <Button
                icon={Icons.Filter}
                badge={appliedFiltersInMenuCount({ smallScreen })}
                badgeDescription={t("number_of_filters_applied")}
                variant="secondary"
                disabled={showFilterMenu}
                id={`filter-${filterId}`}
                ref={setRef}
                data-testid={testId}
                aria-haspopup="true"
                aria-controls={`filterContent-${filterId}`}
                aria-expanded={showFilterMenu}
                data-heart-component="FilterButton"
                onClick={() => setShowFilterMenu(!showFilterMenu)}
                {...getRefProps}
              >
                {t("filter")}
              </Button>
            </Flex>
          </If>
        </FlexItem>
        <If condition={showFilterMenu}>
          <Flex
            column
            gap="300"
            id={`filterContent-${filterId}`}
            aria-labelledby={`filter-${filterId}`}
            ref={setFloat}
            style={{ ...floatStyles, maxWidth: "80vw" }}
            className={styles.filterContent}
            {...getFloatProps}
          >
            <Flex>
              <Button
                onClick={() => {
                  applyFilters(filterValues);
                  setShowFilterMenu(false);
                }}
              >
                {t("apply_filters")}
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowFilterMenu(false);
                  setFilterValues(
                    preserveOnClear ? pick(filterValues, [preserveOnClear]) : {}
                  );
                  clearFilters({ preserveOnClear });
                }}
              >
                {t("clear_filters")}
              </Button>
            </Flex>
            {menuFilters.map(filter => (
              <TableFilter
                key={filter.field}
                filter={filter}
                filterValues={filterValues}
                setFilterValuesAttr={setFilterValuesAttr}
              />
            ))}
          </Flex>
        </If>
      </Flex>
    );
  };

  return (
    <LiveRegion>
      <ResolutionOnly small over>
        {renderFilterMenu({})}
      </ResolutionOnly>
      <ResolutionOnly small under>
        {renderFilterMenu({ smallScreen: true })}
      </ResolutionOnly>
    </LiveRegion>
  );
};
Filter.propTypes = {
  applyFilters: PropTypes.func.isRequired,
  appliedFilterValueFor: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      customDatesLabels: PropTypes.shape({
        start: PropTypes.string.isRequired,
        end: PropTypes.string.isRequired,
      }),
      type: PropTypes.oneOf([
        "autocomplete_select",
        "custom_dates",
        "date",
        "dropdown",
        "search",
        "select",
      ]).isRequired,
      field: PropTypes.string.isRequired,
      /** For type `autocomplete_select`, a gql query, which should take
       * in an `inputQuery` argument to determine options for the dropdown
       */
      query: PropTypes.object,
      /** For type `autocomplete_select`, a function that will be called with
       * the query results, which should return the options in an array of
       * `{label, value}` objects
       */
      valuesFromResponse: PropTypes.func,
      /** used for select and autocomplete_select filters to allow
       * multiple selections */
      isMulti: PropTypes.bool,
      /** the query will not be executed unless all filters with
       * required === true are set */
      required: PropTypes.bool,
      /** function to evaluate if the variable is considered set. if
       * unspecified, any non-undefined value is considered set.
       */
      isSet: PropTypes.func,
      /** if true, shows a link to "select all" options for a "select"
       * component where isMulti is true.
       */
      showSelectAll: PropTypes.bool,
      /** Indicates that this filter should be extracted as a "top level"
       * filter, sitting outside the Filter menu and updating the table
       * data on filter change rather than on clicking "Apply Filters"
       *
       * On mobile, this filter will be collapsed back into the Filter
       * menu to make our pages more mobile friendly
       *
       * Only one filter can be pulled outside the Filter menu,
       */
      topLevel: PropTypes.bool,
    })
  ),
  /** Test ID for Cypress or Jest */
  "data-testid": PropTypes.string,
};

export default Filter;
