import { Button, Flex, GraphQLDataTable } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";
import { newPreventionAgencyServiceReferralPath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyServiceReferral from "@graphql/queries/prevention/AgencyServiceReferrals.graphql";

const { t } = translationWithRoot(
  "prevention.profile_page.agency_service_referrals_tab"
);

const columns = [
  {
    columnName: { name: "Name" },
    id: "ServiceName",
    cell: data => <span>{data.agencyService.name}</span>,
    sortBy: "agency_services.name",
  },
  {
    columnName: { name: "Individual(s) Receiving Service" },
    cell: data => (
      <span>{data.recipients.map(human => human.fullName).join("; ")}</span>
    ),
    id: "recipients",
  },
  {
    columnName: { name: "Actions", justify: "end" },
    cell: () => <a href={"#todo"}>TODO - View Records of Encounter</a>,
    id: "actions",
  },
];

/**
 * Renders a table of agency service referrals for a recipient
 *
 * @param recipientId - the recipient ID
 * @returns {JSX.Element}
 * @constructor
 */
const AgencyServiceReferralsTab = ({ recipientId }) => (
  <GraphQLDataTable
    columns={columns}
    queryTitle={"preventionAgencyServiceReferral"}
    query={AgencyServiceReferral}
    defaultFilters={{ recipientId }}
    filters={
      [
        /** TODO(ENG-19207) Add filters for referrals  */
      ]
    }
    actions={
      <Flex column justify="end">
        <Button
          variant="secondary"
          href={newPreventionAgencyServiceReferralPath()}
        >
          {t("create_service_referral")}
        </Button>
      </Flex>
    }
  />
);

AgencyServiceReferralsTab.propTypes = {
  recipientId: PropTypes.number.isRequired,
};

export default AgencyServiceReferralsTab;
