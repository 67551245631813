import { gql, useQuery } from "@apollo/client";
import {
  Flex,
  InputCheckbox,
  InputDate,
  InputDropdown,
  InputText,
  Link,
  LoadingOverlay,
  Surface,
  Text,
} from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";
import PlacementErrors from "@components/placement_errors/PlacementErrors";

import BintiPropTypes from "@lib/BintiPropTypes";

import CareProviderDropdown from "./CareProviderDropdown";
import PlacementEndedReason from "./PlacementEndedReason";

const { t } = translationWithRoot("placement_period");

const showDescriptionOptions = t("placement_type_descriptions");

const hidePreAdoptiveOptions = {
  office: "Office",
  other: "Other",
  hospitalization: "Hospitalization",
  silp: "Independent Living",
  hotel: "Hotel",
  juvenile_detention_center: "Juvenile Detention Center",
  receiving_center: "Receiving Center",
  shelter: "Shelter",
};

const PlacementPeriodForm = ({
  childId,
  placementPeriodId,
  formState,
  setFormAttribute,
  errors,
}) => {
  const { data, loading } = useQuery(
    gql`
      query PlacementPeriodForm($childId: ID!, $placementPeriodId: ID) {
        placementPeriodFormData(
          childId: $childId
          placementPeriodId: $placementPeriodId
        ) {
          states {
            label
            value
          }
          placementPeriodPlacementTypes {
            label
            value
          }
          permittedAttributes
          hidePlacementType
          headsUpText
          isCaseManagementEnabled
        }
      }
    `,
    {
      variables: {
        childId,
        placementPeriodId: placementPeriodId,
      },
    }
  );

  const placementPeriodTypeOptions =
    data?.placementPeriodFormData?.placementPeriodPlacementTypes ||
    (formState.placementType
      ? [{ label: formState.placementType, value: formState.placementType }]
      : []);

  const {
    states,
    permittedAttributes,
    hidePlacementType,
    headsUpText,
    isCaseManagementEnabled,
  } = data?.placementPeriodFormData || {
    permittedAttributes: [],
    states: [],
    hidePlacementType: false,
  };

  const shouldShowFamilyInfoForm =
    formState.placementType === "placed_with_ooc_family" ||
    formState.placementType === "provider_not_in_binti" ||
    (formState.placementType === "placed_with_private_agency" &&
      formState.careProvider &&
      formState.careProvider.split(":")[0] === "AgencyPlacement");

  return (
    <LoadingOverlay active={loading}>
      <Flex column>
        <If condition={errors?.length > 0}>
          <PlacementErrors
            errors={errors}
            path={`/admin/children/${childId}/placement_periods`}
          />
        </If>
        <If condition={headsUpText?.length > 0}>
          {headsUpText?.map(text => (
            <Text as="div" key={text}>
              {text}
            </Text>
          ))}
        </If>
        <If condition={!hidePlacementType}>
          <InputDropdown
            required
            disabled={!permittedAttributes.includes("placement_type")}
            label={t("placement_type")}
            values={placementPeriodTypeOptions}
            value={formState.placementType}
            onChange={setFormAttribute("placementType")}
          />
          <If condition={formState.placementType != null}>
            <CareProviderDropdown
              childId={childId}
              placementPeriodId={placementPeriodId}
              formState={formState}
              setFormAttribute={setFormAttribute}
            />
          </If>
          <If condition={showDescriptionOptions[formState.placementType]}>
            <InputText
              label={showDescriptionOptions[formState.placementType]}
              required
              rows="4"
              value={formState.placementTypeDescription}
              onChange={setFormAttribute("placementTypeDescription")}
            />
          </If>
        </If>
        <If condition={shouldShowFamilyInfoForm}>
          <Surface title={t("family_info_form")} hideTitle>
            <InputText
              label={t("family_name")}
              value={formState.privateAgencyFamilyName}
              onChange={setFormAttribute("privateAgencyFamilyName")}
            />
            <InputText
              label={t("phone_number")}
              value={formState.privateAgencyFamilyPhoneNumber}
              onChange={setFormAttribute("privateAgencyFamilyPhoneNumber")}
            />
            <InputText
              label={t("license_number")}
              value={formState.privateAgencyFamilyLicenseNumber}
              onChange={setFormAttribute("privateAgencyFamilyLicenseNumber")}
            />
            <InputText
              label={t("street_address")}
              value={formState.privateAgencyAddressStreet}
              onChange={setFormAttribute("privateAgencyAddressStreet")}
            />
            <InputText
              label={t("city")}
              value={formState.privateAgencyAddressCity}
              onChange={setFormAttribute("privateAgencyAddressCity")}
            />
            <InputDropdown
              label={t("state")}
              values={states}
              value={formState.privateAgencyAddressState}
              onChange={setFormAttribute("privateAgencyAddressState")}
            />
            <InputText
              label={t("zip_code")}
              value={formState.privateAgencyAddressZipcode}
              onChange={setFormAttribute("privateAgencyAddressZipcode")}
            />
          </Surface>
        </If>
        <InputDate
          label={t("date_child_joined_placement")}
          required
          disabled={!permittedAttributes.includes("start_date")}
          value={formState.startDate}
          onChange={setFormAttribute("startDate")}
        />
        <InputCheckbox
          label={
            <Fragment>
              Give this provider access to this{" "}
              <Link href="https://binti.zendesk.com/hc/en-us/articles/4410589346579-Child-Profile-in-Family-Portal">
                child&apos;s profile
              </Link>{" "}
              in the Binti portal
            </Fragment>
          }
          value={formState.canFamilyViewChildProfile}
          description={I18n.t(
            "views.admin.placements.placement_period.child_profile_access.hint"
          )}
          disabled={
            !permittedAttributes.includes("can_family_view_child_profile")
          }
          onChange={setFormAttribute("canFamilyViewChildProfile")}
        />
        <If condition={hidePreAdoptiveOptions[formState.placementType] == null}>
          <InputCheckbox
            label={I18n.t(
              "views.admin.placements.placement_period.pre_adoptive.label"
            )}
            value={formState.isPreAdoptionPlacement}
            disabled={
              !permittedAttributes.includes("is_pre_adoption_placement")
            }
            onChange={setFormAttribute("isPreAdoptionPlacement")}
          />
        </If>
        <InputDate
          disabled={!permittedAttributes.includes("end_date")}
          label={I18n.t(
            "javascript.components.placement_end.date_placement_ended"
          )}
          value={formState.endDate}
          onChange={setFormAttribute("endDate")}
        />
        <If condition={formState.endDate}>
          <PlacementEndedReason
            childId={childId}
            placementPeriodId={placementPeriodId}
            daysOfNotice={formState.daysOfNotice}
            endDate={formState.endDate}
            endedExplanation={formState.placementEndedReasonExplanation}
            endedReason={formState.placementEndedReason}
            isCurrentPlacement={
              formState.child?.currentPlacementId === formState.id
            }
            requester={formState.placementEndedRequester}
            disabled={!permittedAttributes.includes("end_date")}
            setFormAttribute={setFormAttribute}
          />
        </If>
        <If condition={isCaseManagementEnabled}>
          <InputCheckbox
            label={t("should_pay_standard_provider")}
            value={formState.shouldPayStandardProvider}
            disabled={
              !permittedAttributes.includes("should_pay_standard_provider")
            }
            onChange={setFormAttribute("shouldPayStandardProvider")}
          />
        </If>
      </Flex>
    </LoadingOverlay>
  );
};

PlacementPeriodForm.propTypes = {
  childId: BintiPropTypes.ID,
  placementPeriodId: BintiPropTypes.ID,
  formState: PropTypes.object,
  setFormAttribute: PropTypes.func,
  hidePlacementType: PropTypes.bool,
  errors: PropTypes.arrayOf(String),
};

export default PlacementPeriodForm;
