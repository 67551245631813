import {
  InputDate,
  InputDropdown,
  InputFilterable,
  InputFilterableAsync,
  InputHidden,
  InputText,
  InputTextarea,
} from "@heart/components";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

/** UI for creating or editing a case note.
 *    Applicant Data > Case Notes > New|Edit
 *    Child Data > Case Notes > New|Edit
 */
class CaseNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      primaryApplicant: {
        selectedValue: {
          value: this.props.adult_profile_id,
          label: this.props.family_name,
        },
      },
      child: {
        selectedValue: {
          value: this.props.child_id,
          label: this.props.child_name,
        },
      },
      caseNoteType: this.props.case_note_type || "",
      activityTypes: {
        selectedValue:
          this.props.child_activity_types.map(value => ({
            value,
            label: value,
          })) || "",
      },
    };

    this.defaultContactMethods = {
      values: this.props.other_contact_methods,
    };

    this.contactMethodFromCaseNoteType = {
      complaint_or_violation: {
        values: this.props.complaint_contact_methods,
      },
      case_update: this.defaultContactMethods,
      family_evaluation: this.defaultContactMethods,
      recruiting: this.defaultContactMethods,
      placement: this.defaultContactMethods,
      support_services: this.defaultContactMethods,
      next_planned_contact: this.defaultContactMethods,
      other: this.defaultContactMethods,
    };
  }

  handleCaseNoteTypeChanged = selected => {
    this.setState({ caseNoteType: selected });
  };

  loadAdultProfileOptions = (prefix, callback) => {
    if (prefix.length < 3) {
      callback([]);
    } else {
      $.ajax({
        url: "/admin/end_users/search_families_for_casenotes_autocomplete",
        dataType: "json",
        contentType: "application/json",
        data: { term: prefix },
        success: results => {
          callback(results);
        },
      });
    }
  };

  loadChildOptions = (prefix, callback) => {
    if (prefix.length < 3) {
      callback([]);
    } else {
      $.ajax({
        url: "/admin/children/children_autocomplete",
        dataType: "json",
        contentType: "application/json",
        data: { term: prefix },
        success: results => {
          callback(results.results);
        },
      });
    }
  };

  onPrimaryApplicantSelect = applicant => {
    if (applicant === null) {
      this.setState({
        primaryApplicant: {
          selectedValue: {},
        },
      });
    } else {
      this.setState({
        primaryApplicant: {
          selectedValue: { value: applicant.value, label: applicant.label },
        },
      });
    }
  };

  onChildSelect = child => {
    if (child === null) {
      this.setState({
        primaryApplicant: {
          selectedValue: {},
        },
      });
    } else {
      this.setState({
        child: {
          selectedValue: { value: child.value, label: child.label },
        },
      });
    }
  };

  onActivityTypesChange = activityTypes => {
    this.setState({
      activityTypes: { selectedValue: activityTypes },
    });
  };

  render() {
    const { caseNoteType, primaryApplicant, child, activityTypes } = this.state;

    const childActivityTypeOptions = this.props.child_activity_type_options.map(
      value => ({
        label: value,
        value,
      })
    );

    let bodyLabel = "Case Note";
    if (caseNoteType === "approval_date_estimate") {
      bodyLabel =
        "Case Note - include an update on where the case is and what's remaining to be done";
    } else if (caseNoteType === "next_planned_contact") {
      bodyLabel = "Planned Contact";
    }

    return (
      <div className="contains-inputs">
        {this.props.child_case_note && (
          <div>
            <InputFilterableAsync
              label="Associated with Family"
              name="case_note[adult_profile_id]"
              value={primaryApplicant.selectedValue}
              cacheOptions={true}
              loadOptions={this.loadAdultProfileOptions}
              onChange={this.onPrimaryApplicantSelect}
              id="family-autocomplete"
              filterOptions={options => options}
            />
            <InputHidden
              name="case_note[child_id]"
              value={this.props.child_id}
            />
            <InputHidden name="case_note[case_note_type]" value="placement" />
          </div>
        )}
        {!this.props.child_case_note && (
          <InputDropdown
            required
            name="case_note[case_note_type]"
            label="Case Note Type"
            onChange={this.handleCaseNoteTypeChanged}
            values={this.props.case_note_type_values}
            value={caseNoteType}
          />
        )}
        <InputDate
          value={this.props.date}
          label="Date"
          name="case_note[date]"
          required
        />
        {this.props.child_case_note && (
          <InputFilterable
            label="Activity Types"
            name="case_note[child_activity_types][]"
            isMulti={true}
            onChange={this.onActivityTypesChange}
            values={childActivityTypeOptions}
            value={activityTypes.selectedValue}
            id="case-note-ff-prioritiziatin"
          />
        )}
        {!this.props.child_case_note &&
          (caseNoteType === "placement" ||
            caseNoteType === "placement_approval") && (
            <InputFilterableAsync
              label="Associated with child"
              name="case_note[child_id]"
              value={child.selectedValue}
              cacheOptions={true}
              loadOptions={this.loadChildOptions}
              defaultOptions
              onChange={this.onChildSelect}
              filterOptions={options => options}
            />
          )}
        {this.contactMethodFromCaseNoteType[caseNoteType] && (
          <InputDropdown
            required
            name="case_note[contact_method]"
            label="Contact Method"
            values={this.contactMethodFromCaseNoteType[caseNoteType].values}
            value={this.props.contact_method}
          />
        )}
        {caseNoteType === "support_services" && (
          <Fragment>
            <InputDropdown
              required
              name="case_note[support_service_type]"
              label="Support Service Type"
              values={this.props.support_service_type_values}
              value={this.props.support_service_type}
            />
            <InputText
              type="number"
              required
              name="case_note[amount_spent]"
              label="Amount Spent"
              value={this.props.amount_spent}
              step="0.01"
            />
          </Fragment>
        )}
        <InputTextarea
          label={bodyLabel}
          required
          rows={10}
          value={this.props.body || ""}
          name="case_note[body]"
          id="case_note_body"
        />
        {caseNoteType === "approval_date_estimate" && (
          <InputDate
            value={this.props.estimated_approval_date}
            label="Estimated Approval Date"
            name="case_note[estimated_approval_date]"
            required={true}
          />
        )}
        {/* Saving always clears the review required state, if any */}
        <InputHidden name="case_note[review_required]" value="0" />
      </div>
    );
  }
}

CaseNote.propTypes = {
  amount_spent: PropTypes.string,
  body: PropTypes.string,
  case_note_type: PropTypes.string,
  case_note_type_values: PropTypes.array,
  child_activity_types: PropTypes.array,
  child_activity_type_options: PropTypes.array,
  contact_method: PropTypes.string,
  contact_method_values: PropTypes.array,
  date: PropTypes.string,
  estimated_approval_date: PropTypes.string,
  support_service_type: PropTypes.string,
  support_service_type_values: PropTypes.array,
  adult_profile_id: PropTypes.string,
  family_name: PropTypes.string,
  child_id: PropTypes.string,
  child_name: PropTypes.string,
  child_case_note: PropTypes.bool,
  complaint_contact_methods: PropTypes.array,
  other_contact_methods: PropTypes.array,
};
export default CaseNote;
