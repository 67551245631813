import completedIcon from "green_check.png";
import PropTypes from "prop-types";
import errorIcon from "red_warning.png";
import warningIcon from "yellow_warning.png";

import styles from "./SummaryItem.module.scss";

/**
 * A summary tile on the homepage.
 */
const SummaryItem = ({
  link,
  showWarningIcon,
  showErrorIcon,
  showCompletedIcon,
  count,
  label,
}) => (
  <a href={link} data-testid="summary-item-link">
    <div className={styles.summaryItem}>
      <div className={styles.count} data-testid="summary-item-count">
        {count == null ? "---" : count}
      </div>

      <div className={styles.label} data-testid="summary-item-label">
        {label}
      </div>

      {showWarningIcon && count > 0 && (
        <img
          className={styles.icon}
          src={warningIcon}
          alt=""
          data-testid="summary-item-warning"
        />
      )}

      {showErrorIcon && count > 0 && (
        <img
          className={styles.icon}
          src={errorIcon}
          alt=""
          data-testid="summary-item-error"
        />
      )}

      {showCompletedIcon && count === 0 && (
        <img
          className={styles.icon}
          src={completedIcon}
          alt=""
          data-testid="summary-item-completed"
        />
      )}

      {showCompletedIcon && count === 0 && (
        <div className={styles.comment}>Great job!</div>
      )}
    </div>
  </a>
);

SummaryItem.propTypes = {
  link: PropTypes.string,
  label: PropTypes.string,
  count: PropTypes.number,
  showWarningIcon: PropTypes.bool,
  showErrorIcon: PropTypes.bool,
  showCompletedIcon: PropTypes.bool,
};
export default SummaryItem;
