import { toast as toastifyToast } from "react-toastify";

import ToastMessage from "./ToastMessage";

const toast = ({ type, title, message, ...toastifyOptions }) =>
  toastifyToast(<ToastMessage title={title} message={message} />, {
    type,
    ...toastifyOptions,
  });

const setSessionStorageItem = type => message =>
  sessionStorage.setItem("flash", JSON.stringify([{ type, message }]));

/** Use these functions to create runtime notifications!
 * See `<ToastContainer>` / storybook for documentation. */
const toastFunctions = {
  informative: args => toast({ ...args, type: "info" }),
  success: args => toast({ ...args, type: "success" }),
  notable: args => toast({ ...args, type: "warning", autoClose: false }),
  negative: args => toast({ ...args, type: "error", autoClose: false }),
  onNextPageLoad: {
    informative: setSessionStorageItem("info"),
    success: setSessionStorageItem("success"),
    notable: setSessionStorageItem("warning"),
    negative: setSessionStorageItem("error"),
  },
};

export default toastFunctions;
export const TOAST_TYPES = Object.keys(toastFunctions);
