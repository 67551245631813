import { gql, useQuery } from "@apollo/client";
import { Flex, LoadingOverlay } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment } from "react";

import { translationWithRoot } from "@components/T";

import SummaryItemGroup from "./SummaryItemGroup";

const { t } = translationWithRoot("agency_group");

export const OpenPlacementSearchesQuery = gql`
  query OpenPlacementSearchesQuery($filteredWorkerIds: [ID!]) {
    openPlacementSearchesSummary(filteredWorkerIds: $filteredWorkerIds)
  }
`;
export const MissingPlacementDataQuery = gql`
  query MissingPlacementDataQuery {
    missingPlacementDataSummary
  }
`;
export const MissingChildDataQuery = gql`
  query MissingChildDataQuery {
    missingChildDataSummary
  }
`;
export const SuggestedSearchesQuery = gql`
  query SuggestedSearchesQuery {
    suggestedSearchesSummary
  }
`;
export const InitialApplicationsQuery = gql`
  query InitialApplicationsQuery($filteredWorkerIds: [ID!], $agencyId: ID!) {
    initialApplicationsSummary(
      filteredWorkerIds: $filteredWorkerIds
      agencyId: $agencyId
    )
  }
`;
export const RenewalApplicationsQuery = gql`
  query RenewalApplicationsQuery($filteredWorkerIds: [ID!], $agencyId: ID!) {
    renewalApplicationsSummary(
      filteredWorkerIds: $filteredWorkerIds
      agencyId: $agencyId
    )
  }
`;
export const CaseManagementQuery = gql`
  query CaseManagementQuery {
    youthCaseLoadSummary
  }
`;

export const AiReviewQuery = gql`
  query AiReviewQuery {
    aiReviewSummary
  }
`;

/**
 * The root Homepage component for displaying all the summary items.
 */
export const HomepageComponentGroup = ({
  aiReviewSummaryPlaceholder = null,
  suggestedSearchesPlaceholder = null,
  missingChildDataPlaceholder = null,
  missingPlacementDataPlaceholder = null,
  placementSearchesPlaceholder = null,
  initialsDataPlaceholder = null,
  renewalsDataPlaceholder = null,
  caseManagementDataPlaceholder = null,
  queryParams,
}) => {
  const {
    loading: isLoadingAiReviewSummary,
    error: aiReviewSummaryError,
    data: aiReviewSummaryData,
  } = useQuery(AiReviewQuery, { skip: !aiReviewSummaryPlaceholder });

  const {
    loading: isLoadingOpenPlacementSearches,
    error: openPlacementSearchesError,
    data: openPlacementSearchesData,
  } = useQuery(OpenPlacementSearchesQuery, {
    variables: { filteredWorkerIds: queryParams.filteredWorkerIds },
    skip: !placementSearchesPlaceholder,
  });

  const {
    loading: isLoadingMissingPlacementData,
    error: missingPlacementDataError,
    data: missingPlacementData,
  } = useQuery(MissingPlacementDataQuery, {
    skip: !missingPlacementDataPlaceholder,
  });

  const {
    loading: isLoadingMissingChildData,
    error: missingChildDataError,
    data: missingChildData,
  } = useQuery(MissingChildDataQuery, {
    skip: !missingChildDataPlaceholder,
  });

  const {
    loading: isLoadingSuggestedSearches,
    error: suggestedSearchesError,
    data: suggestedSearchesData,
  } = useQuery(SuggestedSearchesQuery, {
    skip: !suggestedSearchesPlaceholder,
  });

  const {
    loading: isLoadingInitialsData,
    error: initialsDataError,
    data: initialsData,
  } = useQuery(InitialApplicationsQuery, {
    variables: {
      filteredWorkerIds: queryParams.filteredWorkerIds,
      agencyId: queryParams.agencyId,
    },
    skip: !initialsDataPlaceholder,
  });

  const {
    loading: isLoadingRenewalsData,
    error: renewalsDataError,
    data: renewalsData,
  } = useQuery(RenewalApplicationsQuery, {
    variables: {
      filteredWorkerIds: queryParams.filteredWorkerIds,
      agencyId: queryParams.agencyId,
    },
    skip: !renewalsDataPlaceholder,
  });

  const {
    loading: isLoadingCaseManagementData,
    error: caseManagementDataError,
    data: caseManagementData,
  } = useQuery(CaseManagementQuery, {
    skip: !caseManagementDataPlaceholder,
  });

  return (
    <Fragment>
      <Flex row gap="0" wrap>
        <If condition={!aiReviewSummaryError && aiReviewSummaryPlaceholder}>
          <SummaryItemGroup
            title={t("ai_review_summary")}
            summaryItemGroups={
              isLoadingAiReviewSummary
                ? aiReviewSummaryPlaceholder
                : aiReviewSummaryData?.aiReviewSummary
            }
          />
        </If>
        <If condition={!initialsDataError && initialsDataPlaceholder}>
          <LoadingOverlay active={isLoadingInitialsData}>
            <SummaryItemGroup
              title={t("initials")}
              summaryItemGroups={
                isLoadingInitialsData
                  ? initialsDataPlaceholder
                  : initialsData?.initialApplicationsSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If condition={!renewalsDataError && renewalsDataPlaceholder}>
          <LoadingOverlay active={isLoadingRenewalsData}>
            <SummaryItemGroup
              title={t("renewals")}
              summaryItemGroups={
                isLoadingRenewalsData
                  ? renewalsDataPlaceholder
                  : renewalsData?.renewalApplicationsSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If
          condition={
            !openPlacementSearchesError && placementSearchesPlaceholder
          }
        >
          <LoadingOverlay active={isLoadingOpenPlacementSearches}>
            <SummaryItemGroup
              title={t("placement_searches")}
              summaryItemGroups={
                isLoadingOpenPlacementSearches
                  ? placementSearchesPlaceholder
                  : openPlacementSearchesData?.openPlacementSearchesSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If condition={!suggestedSearchesError && suggestedSearchesPlaceholder}>
          <LoadingOverlay active={isLoadingSuggestedSearches}>
            <SummaryItemGroup
              title={t("suggested_searches")}
              summaryItemGroups={
                isLoadingSuggestedSearches
                  ? suggestedSearchesPlaceholder
                  : suggestedSearchesData?.suggestedSearchesSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If
          condition={
            !missingPlacementDataError && missingPlacementDataPlaceholder
          }
        >
          <LoadingOverlay active={isLoadingMissingPlacementData}>
            <SummaryItemGroup
              title={t("placement_preferences")}
              summaryItemGroups={
                isLoadingMissingPlacementData
                  ? missingPlacementDataPlaceholder
                  : missingPlacementData?.missingPlacementDataSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If condition={!missingChildDataError && missingChildDataPlaceholder}>
          <LoadingOverlay active={isLoadingMissingChildData}>
            <SummaryItemGroup
              title={t("child_data_completion")}
              summaryItemGroups={
                isLoadingMissingChildData
                  ? missingChildDataPlaceholder
                  : missingChildData?.missingChildDataSummary
              }
            />
          </LoadingOverlay>
        </If>
        <If
          condition={!caseManagementDataError && caseManagementDataPlaceholder}
        >
          <LoadingOverlay active={isLoadingCaseManagementData}>
            <SummaryItemGroup
              title={t("youth_case_load.title")}
              summaryItemGroups={
                isLoadingCaseManagementData
                  ? caseManagementDataPlaceholder
                  : caseManagementData?.youthCaseLoadSummary
              }
            />
          </LoadingOverlay>
        </If>
      </Flex>
    </Fragment>
  );
};

HomepageComponentGroup.propTypes = {
  aiReviewSummaryPlaceholder: PropTypes.object,
  suggestedSearchesPlaceholder: PropTypes.object,
  missingChildDataPlaceholder: PropTypes.object,
  missingPlacementDataPlaceholder: PropTypes.object,
  placementSearchesPlaceholder: PropTypes.object,
  initialsDataPlaceholder: PropTypes.object,
  renewalsDataPlaceholder: PropTypes.object,
  caseManagementDataPlaceholder: PropTypes.object,
  queryParams: PropTypes.object,
};

export default HomepageComponentGroup;
