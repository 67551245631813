import { Flex, Menu } from "@heart/components";
import classNames from "classnames";
import { flatMap, sortBy } from "lodash";

import { translationWithRoot } from "@components/T";

import styles from "./Header.module.scss";
import headerPropTypes from "./headerPropTypes";

const { t: headerT } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});

const menuItemForLink = link => ({
  description: headerT(link.key),
  href: link.url,
});

/** Smaller screen/mobile version of our site level menu */
const HamburgerMenu = ({ links }) => (
  <Flex align="center" className={styles.hamburgerContainer}>
    <Menu
      menuDescription="All Navigation Links" // TODO ENG-14960
      buttonComponent={({ isOpen, ...buttonProps }) => (
        <button
          className={classNames(styles.unstyledButton, styles.hamburgerButton)}
          aria-label={buttonProps.description}
          title={buttonProps.description}
          id="hamburger-menu"
          {...buttonProps}
        >
          <div className={styles.icon} />
        </button>
      )}
      // TODO ENG-14959 Group these menu items.  For now they're just alphabetized.
      linkItems={sortBy(
        flatMap(links, linkOrFolder =>
          linkOrFolder.submenu_items ? linkOrFolder.submenu_items : linkOrFolder
        ).map(menuItemForLink),
        "description"
      )}
    />
  </Flex>
);

HamburgerMenu.propTypes = { links: headerPropTypes.allLinksPropType };

export default HamburgerMenu;
