import { Flex, Icons, Menu, Text } from "@heart/components";
import classNames from "classnames";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import styles from "./Header.module.scss";
import headerPropTypes from "./headerPropTypes";

const { t: headerT } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});

const menuItemForLink = link => ({
  description: headerT(link.key),
  content: link.call_to_action ? (
    <Flex>
      <Text textColor="emphasis-500">
        <Icons.Plus />
      </Text>
      {headerT(link.key)}
    </Flex>
  ) : undefined,
  href: link.url,
});

/** Larger screen/desktop version of each dropdown in our site level menu */
const DesktopHeaderFolder = ({ label, links }) => (
  <Menu
    menuDescription={label}
    buttonComponent={({ isOpen, ...buttonProps }) => (
      <button
        className={styles.unstyledButton}
        aria-label={buttonProps.description}
        title={buttonProps.description}
        {...buttonProps}
      >
        <div
          className={classNames(styles.desktopHeaderFolder, {
            [styles.open]: isOpen,
          })}
        >
          {label}
          <div
            className={classNames(
              styles.folderIcon,
              styles[`folderIcon${isOpen ? "Open" : "Closed"}`]
            )}
          />
        </div>
      </button>
    )}
    linkItems={links.map(menuItemForLink)}
  />
);
DesktopHeaderFolder.propTypes = {
  label: PropTypes.string,
  links: PropTypes.arrayOf(headerPropTypes.linkPropType),
};

export default DesktopHeaderFolder;
