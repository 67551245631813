import { Flex, Icons, LiveRegion, Text } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";

const { t } = translationWithRoot("heart.components.inputs.input_ssn");

/** A component that hides the details of a SSN until a user
 * takes action to reveal them.
 *
 * After 15 seconds the SSN will be hidden again to prevent users
 * from leaving sensitive information visible
 */
const DisplaySSN = ({ ssn, showLabel }) => {
  const [showSSN, setShowSSN] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowSSN(false);
    }, 15000);
  }, [showSSN]);

  if (isEmpty(ssn)) return null;

  const maskedSSN = `XXX-XX-${ssn.slice(-4)}`;
  return (
    <LiveRegion>
      <Flex align="center">
        <If condition={showLabel}>
          <Text>
            <b>{t("ssn")}</b>:
          </Text>
        </If>
        <Text>{showSSN ? ssn : maskedSSN}</Text>
        <If condition={showSSN}>
          <Icons.EyeSlash
            onClick={() => setShowSSN(false)}
            description={t("hide_ssn")}
          />
        </If>
        <If condition={!showSSN}>
          <Icons.Eye
            onClick={() => setShowSSN(true)}
            description={t("show_ssn")}
          />
        </If>
      </Flex>
    </LiveRegion>
  );
};
DisplaySSN.propTypes = {
  ssn: PropTypes.string,
  showLabel: PropTypes.bool,
};

export default DisplaySSN;
