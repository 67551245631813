import { Flex } from "@heart/components";
import ResolutionOnly from "@heart/components/layout/ResolutionOnly";
import classNames from "classnames";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { useState } from "react";

import { translationWithRoot } from "@components/T";

import DesktopHeaderFolder from "./DesktopHeaderFolder";
import EnvironmentName from "./EnvironmentName";
import HamburgerMenu from "./HamburgerMenu";
import styles from "./Header.module.scss";
import HeaderRight from "./HeaderRight";
import SearchBar from "./SearchBar";
import headerPropTypes from "./headerPropTypes";
import LogoWhite from "./logo-white.svg";

const { t: headerT } = translationWithRoot("admin.header_links", {
  escapeJavascriptRoot: true,
});

/**
 * Binti's header for authenticated caseworkers and Binti admins
 *
 * ### Cypress
 *   * `cy.clickMenu(["Caregivers", "Intake Caregiver"])` to click link folders
 *   * `cy.clickSecondaryNavDropdown()` to click the profile menu
 *     * TODO ENG-15224: for now, you have to open it and then target links inside afterwards
 *   * `cy.findHeaderItem(["Reports", "AFCARS"]).should("exist")` to check visibility
 */
const Header = ({
  rootPath,
  links,
  secondaryLinks,
  user,
  phantomModeEnabled,
  k8sNamespace,
  searchPermitted,
  voiceNavigationLicensingMainMenuLink,
}) => {
  const [showSearchBar, setShowSearchBar] = useState(false);

  return (
    <header data-heart-component="Header">
      <nav className={classNames(styles.container)}>
        <a className="skip-link" href="#skip-heading">
          {I18n.t("views.layouts.application.skip_main_navigation")}
        </a>
        <ResolutionOnly medium under>
          <HamburgerMenu links={links} />
        </ResolutionOnly>
        <a
          href={rootPath}
          className={classNames(
            styles.authenticated,
            styles.logoContainer,
            styles[k8sNamespace]
          )}
          data-testid="header-logo"
        >
          {/** TODO ENG-14960 translate this alt text */}
          <img src={LogoWhite} alt="Binti Logo" />
          <EnvironmentName k8sNamespace={k8sNamespace} />
        </a>
        <Flex justify="end" align="center" grow className={styles.headerBody}>
          {showSearchBar ? (
            <SearchBar onClose={() => setShowSearchBar(false)} />
          ) : (
            <div className={styles.linksContainer}>
              <ResolutionOnly medium over>
                <Flex row gap="0">
                  {links.map(link => (
                    <DesktopHeaderFolder
                      key={link.key}
                      label={headerT(link.key)}
                      links={link.submenu_items}
                    />
                  ))}
                </Flex>
              </ResolutionOnly>
            </div>
          )}
          <HeaderRight
            user={user}
            secondaryLinks={secondaryLinks}
            phantomModeEnabled={phantomModeEnabled}
            showSearchIcon={!showSearchBar && searchPermitted}
            onSearchIconClicked={setShowSearchBar}
            voiceNavigationLicensingMainMenuLink={
              voiceNavigationLicensingMainMenuLink
            }
          />
        </Flex>
        <span id="skip-heading" className="is-hidden-accessible" tabIndex="-1">
          {I18n.t("views.layouts.application.page_content")}
        </span>
      </nav>
    </header>
  );
};

Header.propTypes = {
  rootPath: PropTypes.string,
  links: headerPropTypes.allLinksPropType,
  secondaryLinks: PropTypes.arrayOf(headerPropTypes.linkPropType),
  user: headerPropTypes.userPropType,
  phantomModeEnabled: PropTypes.bool,
  k8sNamespace: PropTypes.string.isRequired,
  searchPermitted: PropTypes.bool,
  /**
   * If provided, the Binti Blue (voice navigation) link will be displayed
   * in the header.
   */
  voiceNavigationLicensingMainMenuLink: PropTypes.string,
};

export default Header;
