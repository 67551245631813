import { Button, Flex, FlexItem, Modal, Text } from "@heart/components";
import InputCsrfToken from "@heart/components/inputs/InputCsrfToken";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { moveCoApplicantAdminApplicationPath } from "routes";

import { translationWithRoot } from "@components/T";

const { T } = translationWithRoot("admin.applications.change_role");

/**
 * A component that allows a user to change the role of a human.
 * @param {object} props
 * @param {string} props.currentRole - The current role of the human
 * @param {string} props.csrfToken - The CSRF token
 * @param {number} props.applicationId - The application ID
 * @param {boolean} props.mayChangeProviderRolesCoApplicant - Whether the current user is allowed to change provider roles for the co-applicant
 * @returns {JSX.Element}
 */
const ChangeRole = ({
  currentRole,
  csrfToken,
  applicationId,
  mayChangeProviderRolesCoApplicant,
}) => {
  // State atom to track if the form is submitting
  const [submitting, setSubmitting] = useState(false);

  // State atom to track if the confirmation modal is hidden
  const [confirmationHidden, setConfirmationHidden] = useState(true);

  // Ref to the form element. Used to submit the form when the user clicks confirm
  const formRef = useRef(null);

  return (
    <Flex align="center" row>
      <Text>
        <T t={`roles.${currentRole}`} />
      </Text>
      <If condition={mayChangeProviderRolesCoApplicant}>
        <FlexItem>
          <form
            ref={formRef}
            onSubmit={() => {
              setSubmitting(true);
            }}
            action={moveCoApplicantAdminApplicationPath(applicationId)}
            method="POST"
          >
            <InputCsrfToken csrfToken={csrfToken} />
            <Button
              variant="secondary"
              submitting={submitting}
              onClick={e => {
                e.preventDefault();
                setConfirmationHidden(false);
              }}
            >
              <T t="convert_to_other_adult" />
            </Button>
          </form>
        </FlexItem>
        <If condition={!confirmationHidden}>
          <Modal
            hidden={confirmationHidden}
            onCancel={() => setConfirmationHidden(true)}
            onSubmit={() => {
              setSubmitting(true);
              formRef.current.submit();
            }}
            submitting={submitting}
            title={<T t="convert_to_other_adult" />}
          >
            <T t="confirm_change_role" />
          </Modal>
        </If>
      </If>
    </Flex>
  );
};

ChangeRole.propTypes = {
  currentRole: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  applicationId: PropTypes.number.isRequired,
  mayChangeProviderRolesCoApplicant: PropTypes.bool.isRequired,
};

export default ChangeRole;
