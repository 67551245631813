import { useMountEffect } from "@react-hookz/web";
import EasySpeech from "easy-speech";
import PropTypes from "prop-types";
import { createContext } from "react";

const TextToSpeechContext = createContext();

export default TextToSpeechContext;

/**
 * Test provider that uses browser TTS to speak text.
 */
export const TestTextToSpeechContextProvider = ({ children }) => {
  useMountEffect(async () => {
    try {
      await EasySpeech.init();
    } catch (e) {
      // eslint-disable-next-line no-alert
      alert("Failed to initialize browser tts");
    }
  });

  return (
    <TextToSpeechContext.Provider
      value={{
        speak: async ({ text }) => {
          await EasySpeech.init();

          // best chrome voice
          const browserTTSVoice =
            EasySpeech.voices().find(v => v.name === "Google US English") ||
            // best available safari voice
            EasySpeech.voices().find(v => v.name.includes("Karen")) ||
            // fallback
            EasySpeech.voices().find(v => v.lang === "en-US");

          const { resolve, promise } = Promise.withResolvers();

          EasySpeech.speak({
            text,
            voice: browserTTSVoice,
            end: resolve,
            error: resolve,
          });

          return promise;
        },
        stopSpeaking: () => {
          EasySpeech.cancel();
        },
      }}
    >
      {children}
    </TextToSpeechContext.Provider>
  );
};

TestTextToSpeechContextProvider.propTypes = {
  children: PropTypes.node,
};
