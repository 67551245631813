import { useMutation } from "@apollo/client";
import { Upload, Surface, PageContainer } from "@heart/components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";

import CreateChildPhotos from "@graphql/mutations/CreateChildPhotos.graphql";
import DeleteChildPhotos from "@graphql/mutations/DeleteChildPhotos.graphql";
import LegacyCreateChildPhotos from "@graphql/mutations/LegacyCreateChildPhotos.graphql";
import LegacyDeleteChildPhotos from "@graphql/mutations/LegacyDeleteChildPhotos.graphql";

import { prepareShrineFiles } from "@lib/graphqlHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";

import Photo from "./Photo";

const { t } = translationWithRoot("photos");

/*
 * PhotoUploader component is responsible for uploading and displaying photos
 * for a child.
 */
const PhotoUploader = ({
  photos: ps,
  childId,
  canUploadChildPhoto,
  canDeleteChildPhoto,
}) => {
  const [photos, setPhotos] = useState(ps);
  const [legacyCreateChildPhotos] = useMutation(LegacyCreateChildPhotos);
  const [legacyDeleteChildPhotos] = useMutation(LegacyDeleteChildPhotos);
  const [createChildPhotos] = useMutation(CreateChildPhotos);
  const [deleteChildPhotos] = useMutation(DeleteChildPhotos);
  const { flag: ffShrineUploads } = useFeatureFlag("ff_shrine_uploads_03_2024");

  if (!canUploadChildPhoto) return null;

  const onUpload = async files => {
    let mutation;
    let mutationName;
    let fileVariables = {};

    if (ffShrineUploads) {
      const shrineFiles = await prepareShrineFiles(files);

      fileVariables = {
        files: shrineFiles,
      };
      mutation = createChildPhotos;
      mutationName = "createChildPhotos";
    } else {
      fileVariables = {
        files,
        category: "childPhoto",
      };

      mutation = legacyCreateChildPhotos;
      mutationName = "legacyCreateChildPhotos";
    }

    const variables = {
      childId,
      ...fileVariables,
    };

    const {
      data: {
        [mutationName]: { uploadedPhotos },
      },
    } = await mutation({
      variables,
    });

    setPhotos([...photos, ...uploadedPhotos]);
  };

  const onDelete = async ({ id: photoId, isShrineFile }) => {
    setPhotos(photos.filter(photo => photo.id !== photoId));

    const mutation = isShrineFile ? deleteChildPhotos : legacyDeleteChildPhotos;

    await mutation({
      variables: {
        childId,
        photoId,
      },
    });
  };

  return (
    <PageContainer>
      <Surface title={t("upload_photos_section")} data-testid="photo-uploader">
        <Upload photosOnly onUpload={onUpload} />
      </Surface>
      <Surface title={t("child_photos_section")}>
        {photos.map(photo => (
          <Photo
            photo={photo}
            key={photo.id}
            onDelete={() => onDelete(photo)}
            isDeleteDisabled={!canDeleteChildPhoto}
          />
        ))}
        <If condition={isEmpty(photos)}>{t("no_photos_to_display")}</If>
      </Surface>
    </PageContainer>
  );
};

PhotoUploader.propTypes = {
  photos: PropTypes.array.isRequired,
  childId: PropTypes.number.isRequired,
  canUploadChildPhoto: PropTypes.bool.isRequired,
  canDeleteChildPhoto: PropTypes.bool.isRequired,
};

export default PhotoUploader;
