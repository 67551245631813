import { ContainsTrustedHTML } from "@heart/components";
import { useMountEffect } from "@react-hookz/web";
import { flatten, endswith } from "lodash";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import BannerContainer from "@components/shared/banner/BannerContainer";

import RailsNotice from "./RailsNotice";
import RailsToast from "./RailsToast";

/**
 * Given a set of Rails flash messages (`flash_messages.to_h` in any controller),
 * render corresponding notification components in React.
 *
 * In short,
 *   * `flash[:long_error]` results in a Notice component (see `RailsNotice`)
 *   * `flash[:*_banner]` results in a Banner.  This is deprecated and will be removed.
 *   * Everything else results in a Toast (see `RailsToast`)
 */
const RailsNotifications = ({ flashMessages = {} }) => {
  const [notifications, setNotifications] = useState([]);

  useMountEffect(() => {
    setNotifications([
      ...(JSON.parse(sessionStorage.getItem("flash")) || []),
      ...flatten(
        Object.entries(flashMessages).map(([type, messages = []]) => [
          ...[].concat(messages).map(message => ({ type, message })),
        ])
      ),
    ]);
    sessionStorage.removeItem("flash");
    setTimeout(() => setNotifications([]), 1000);
  });

  return (
    <Fragment>
      {notifications.map(({ type, message }) => {
        const key = `${type}-${message}`;

        if (type === "long_error") {
          return (
            <RailsNotice
              key={key}
              title={message.title || "Notice"}
              errorList={message.error_list}
            >
              {message.content}
            </RailsNotice>
          );
        }

        if (endswith(type, "_banner")) {
          const bannerType = {
            // just removing the `_banner` suffix
            error_banner: "error",
            notice_banner: "notice",
          }[type];
          return (
            <BannerContainer
              key={key}
              type={bannerType}
              message={
                <ContainsTrustedHTML
                  html={message}
                  trustedSource="Rails call to flash[]"
                />
              }
            />
          );
        }

        return <RailsToast key={key} type={type} message={message} />;
      })}
    </Fragment>
  );
};

RailsNotifications.propTypes = {
  flashMessages: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          error_list: PropTypes.arrayOf(PropTypes.string),
          content: PropTypes.string.isRequired,
        })
      ),
      PropTypes.shape({
        title: PropTypes.string,
        error_list: PropTypes.arrayOf(PropTypes.string),
        content: PropTypes.string,
      }),
    ])
  ),
};

export default RailsNotifications;
