import audioBufferToWav from "audiobuffer-to-wav";

const SILENCE_THRESHOLD = 0.01;

// if the audio is 44.1kHz, this is 23ms
// if the audio is 48kHz, this is 21ms
const CHUNK_SIZE = 1024;

/**
 * Finds first index of "non-silence" in an audio buffer by calculating the
 * average amplitude of each chunk of CHUNK_SIZE samples and returning the
 * index of the first chunk that is not silent.
 *
 * @param buffer single channel audio buffer
 * @returns the index of the first sample in the buffer that is not silent
 */
export const findNonSilenceIndex = (
  buffer,
  // these are just to make testing easier
  {
    chunkSize = CHUNK_SIZE,
    silenceThreshold = SILENCE_THRESHOLD,
    startFrame = 0,
  } = {}
) => {
  let rollingSum = 0;

  for (let i = startFrame; i < buffer.length; i += 1) {
    if (i - startFrame < chunkSize) {
      rollingSum += Math.abs(buffer[i]);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (rollingSum / chunkSize > silenceThreshold) {
      return i - chunkSize;
    }

    rollingSum += Math.abs(buffer[i]) - Math.abs(buffer[i - chunkSize]);
  }

  return -1;
};

/**
 * Finds last index of "non-silence" in an audio buffer by calculating the
 * average amplitude of each chunk of CHUNK_SIZE samples and returning the
 * index of the last chunk that is not silent. This function mirrors
 * findNonSilenceIndex so we don't have to reverse the buffer to find the
 * end. The buffers in use are a little large, so reversing them can be
 * expensive in terms of memory, though surprisingly this isn't actually
 * faster than reversing the buffer and using findNonSilenceIndex.
 *
 * @param buffer single channel audio buffer
 * @returns the index of the last sample in the buffer that is not silent
 */
export const findLastNonSilenceIndex = (
  buffer,
  // these are just to make testing easier
  { chunkSize, silenceThreshold } = {
    chunkSize: CHUNK_SIZE,
    silenceThreshold: SILENCE_THRESHOLD,
  }
) => {
  let rollingSum = 0;

  const firstChunkBoundary = buffer.length - chunkSize - 1;
  for (let i = buffer.length - 1; i >= 0; i -= 1) {
    if (i > firstChunkBoundary) {
      rollingSum += Math.abs(buffer[i]);
      // eslint-disable-next-line no-continue
      continue;
    }

    if (rollingSum / chunkSize > silenceThreshold) {
      return i + chunkSize;
    }

    rollingSum += Math.abs(buffer[i]) - Math.abs(buffer[i + chunkSize]);
  }

  return -1;
};

/**
 * Given an audio buffer, trims silence from the beginning and end of the buffer
 * and returns a WAV file containing the trimmed audio.
 *
 * @param audioBuffer the audio buffer to trim
 * @param testOptions container object for test dependencies
 * @param testOptions.AudioBuffer the AudioBuffer class to use (needs to be
 *                                polyfilled for node/jest)
 * @returns a WAV file containing the trimmed audio as a File
 */
const trimSilence = (
  audioBuffer,
  { AudioBuffer = global.AudioBuffer, startTimeHint } = {}
) => {
  const channelStarts = Array(audioBuffer.numberOfChannels).fill(0);
  const channelEnds = Array(audioBuffer.numberOfChannels).fill(0);
  const startFrame = startTimeHint
    ? Math.floor(startTimeHint * audioBuffer.sampleRate)
    : 0;

  // find the first and last non-silent samples in any channel
  for (let i = 0; i < audioBuffer.numberOfChannels; i += 1) {
    const buffer = audioBuffer.getChannelData(i);
    channelStarts[i] = findNonSilenceIndex(buffer, { startFrame });
    channelEnds[i] = findLastNonSilenceIndex(buffer);
  }

  const start = Math.max(...channelStarts);
  const end = Math.min(...channelEnds);

  // Create a new buffer with the silent parts trimmed off
  const trimmedBuffer = new AudioBuffer({
    length: end - start,
    sampleRate: audioBuffer.sampleRate,
    numberOfChannels: audioBuffer.numberOfChannels,
  });

  for (let i = 0; i < audioBuffer.numberOfChannels; i += 1) {
    const buffer = audioBuffer.getChannelData(i);

    const slice = buffer.slice(start, end);
    trimmedBuffer.copyToChannel(slice, i, 0);
  }

  const wavBytes = audioBufferToWav(trimmedBuffer, { float32: true });

  return {
    wavBytes,
    startTime: start / audioBuffer.sampleRate,
    endTime: end / audioBuffer.sampleRate,
  };
};

export default trimSilence;
